import { get } from "lodash";
import * as dayjs from "dayjs";

import { ShareClassCode } from '../common';
import { FiboModel } from '../fibo/fiboModel';
import { IRawPrice } from '../dbCandle/dbCandle';
import { rawPriceToNumber } from '../utils/price';

export enum InstrumentType {
    Share = 'share',
    Future = 'future',
    Bond = 'bond',
    Index = 'index',
    Crypto = 'crypto',
}

export interface IInstrument {
    instrument_type: InstrumentType;
    name: string;
    figi: string;
    isin: string;
    ticker: string;
    lot: number;
    class_code: ShareClassCode;
    min_price_increment: IRawPrice;
    fibo_one_min: FiboModel;
    fibo_one_hour: FiboModel;
    fibo_one_day: FiboModel;
    fibo_one_month: FiboModel;
    short_enabled_flag: boolean;
    api_trade_available_flag: boolean;
    is_candles_initialised: boolean;
    moex_level: number;
    ratio: number | null;
    for_qual_investor_flag: boolean | null;
    is_deactivated: boolean | undefined;
    // for futures only
    expiration_date: Date | null;
}

export class InstrumentModel {
    instrumentType: InstrumentType;
    name: string;
    figi: string;
    ticker: string;
    lot: number;
    classCode: ShareClassCode;
    minPriceIncrement: number;
    fibo_one_min: FiboModel;
    fibo_one_hour: FiboModel;
    fibo_one_day: FiboModel;
    fibo_one_month: FiboModel;
    moexLevel: number;
    ratio: number | null;
    shortEnabledFlag: boolean;
    apiTradeAvailableFlag: boolean;
    isCandlesInitialised: boolean;
    isDeactivated: boolean;
    forQualInvestorFlag: boolean;
    // for futures only
    expirationDate: Date | null;

    constructor(data: IInstrument) {
        this.instrumentType = data.instrument_type;
        this.name = data.name;
        this.figi = data.figi;
        this.ticker = data.ticker;
        this.lot = data.lot;
        this.classCode = data.class_code;
        this.minPriceIncrement = rawPriceToNumber(data.min_price_increment);
        this.fibo_one_min = data.fibo_one_min;
        this.fibo_one_hour = data.fibo_one_hour;
        this.fibo_one_day = data.fibo_one_day;
        this.fibo_one_month = data.fibo_one_month;
        this.moexLevel = data.moex_level || 0;
        this.ratio = data.ratio;
        this.shortEnabledFlag = data.short_enabled_flag;
        this.apiTradeAvailableFlag = data.api_trade_available_flag;
        this.isCandlesInitialised = data.is_candles_initialised;
        this.isDeactivated = get(data, 'is_deactivated', false);
        this.forQualInvestorFlag = data.for_qual_investor_flag || false;
        // for futures only
        this.expirationDate = data.expiration_date || null;
    }

    isShare = () => this.instrumentType === InstrumentType.Share;

    isFuture = () => this.instrumentType === InstrumentType.Future;

    isCrypto = () => this.instrumentType === InstrumentType.Crypto;

    isActive = () => {
        if(this.isDeactivated) {
            return false;
        }
        if (this.isFuture()) {
            return dayjs().isBefore(this.expirationDate) && this.isCandlesInitialised;
        }
        return this.isCandlesInitialised;
    };
}
