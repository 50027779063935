import { useCallback, useEffect, useState, MouseEvent } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { InstrumentModelWithStats } from '../models/InstrumentModelWithStats';
import IconButton from '@mui/material/IconButton/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import { ConfirmationDialog } from './components/ConfirmationDialog';
import { FormControl, Link, Menu, MenuItem, Paper, TableContainer, TableSortLabel, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import { orderBy as orderByFn } from 'lodash';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

// @ts-ignore
import { prettyPrintPrice } from '@ystb/backend/dist/utils/common';

import AppState from '../AppState';
import { IInstrumentDialogSubmitData, InstrumentDialog } from './components/InstrumentDialog';
import { InstrumentsAutocomplete } from '../components/InstrumentsAutocomplete';

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
    onRequestSort: (event: MouseEvent<unknown>, property: string) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

interface HeadCell {
    id: string;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'ticker',
        numeric: false,
        label: 'Инструмент',
    },
    {
        id: 'actions',
        numeric: false,
        label: '',
    },
    {
        id: 'lastPrice',
        numeric: false,
        label: 'Последняя цена',
    },
    {
        id: 'moexLevel',
        numeric: false,
        label: 'Эшелон Мосбиржи',
    },
    {
        id: 'classCode',
        numeric: false,
        label: 'Режим торгов',
    },
    {
        id: 'ratio',
        numeric: false,
        label: 'Рейтинг',
    },
    {
        id: 'shortEnabledFlag',
        numeric: false,
        label: 'Шорт',
    },
    {
        id: 'forQualInvestorFlag',
        numeric: false,
        label: 'Квал',
    },
    {
        id: 'apiTradeAvailableFlag',
        numeric: false,
        label: 'API',
    },
    {
        id: 'isCandlesInitialised',
        numeric: false,
        label: 'Инит.',
    },
    {
        id: 'lot',
        numeric: false,
        label: 'Лот (шт.)',
    },
    {
        id: 'minPriceIncrement',
        numeric: false,
        label: 'Шаг цены (₽)',
    },
    {
        id: 'riskChangeLowPrice',
        numeric: false,
        label: 'Мин. планка (₽)',
    },
    {
        id: 'riskChangeHighPrice',
        numeric: false,
        label: 'Макс планка (₽)',
    },
];

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: string) => (event: MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding="normal"
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            disabled={headCell.id === 'actions'}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export function InstrumentsTable() {
    const [items, setItems] = useState<InstrumentModelWithStats[]>([]);
    const [selectedInstrument, setSelectedInstrument] = useState<InstrumentModelWithStats | null>(null);
    const [selectedTicker, setSelectedTicker] = useState<string>('');
    const [instrumentMoreButtonEl, setInstrumentMoreButtonEl] = useState<null | HTMLElement>(null);

    const [showInstrumentDialog, setShowInstrumentDialog] = useState<boolean>(false);
    const [showDeleteInstrumentDialog, setShowDeleteInstrumentDialog] = useState<boolean>(false);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<string>('ticker');

    useEffect(() => {
        let newItems = orderByFn([...AppState.instruments], orderBy, order);
        if (selectedTicker) {
            newItems = newItems.filter((item) => item.ticker === selectedTicker);
        }
        setItems(newItems);
    }, [order, orderBy, selectedTicker]);

    // fixme add confirmation dialog
    const handleDeleteInstrumentBtn = useCallback(() => {
        setShowDeleteInstrumentDialog(true);
    }, []);

    const handleEditInstrumentBtn = useCallback(() => {
        setShowInstrumentDialog(true);
    }, []);

    const handleCloseModal = useCallback(() => {
        setShowInstrumentDialog(false);
        setShowDeleteInstrumentDialog(false);
    }, []);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const onSubmit = useCallback(
        async (params: IInstrumentDialogSubmitData) => {
            try {
                const { instrument, ratio } = params;
                await AppState.restClient.updateInstrumentRatio(instrument.ticker, ratio);
                handleCloseModal();
                // fixme after adding WS
                window.location.reload();
            } catch {
                console.warn('Failed to submit new instrument ratio');
            }
        },
        [handleCloseModal]
    );

    const onDeleteInstrumentSubmit = useCallback(async () => {
        try {
            if (selectedInstrument) {
                await AppState.restClient.deleteInstrument(selectedInstrument.isin);
                // fixme after adding WS
                window.location.reload();
            } else {
                console.warn('Failed to remove instrument - selectedInstrument is empty');
            }
        } catch (e) {}
    }, [selectedInstrument]);

    const handleTickerChange = useCallback(
        (event: React.SyntheticEvent, value: any) => {
            setSelectedTicker(value?.ticker || '');
        },
        [setSelectedTicker]
    );

    const handleMoreButton = useCallback(
        (event: React.MouseEvent<HTMLElement>, model: InstrumentModelWithStats) => {
            setSelectedInstrument(model);
            setInstrumentMoreButtonEl(event.currentTarget);
        },
        [setSelectedInstrument, setInstrumentMoreButtonEl]
    );

    const handleInstrumentMenuClose = useCallback(() => {
        setInstrumentMoreButtonEl(null);
    }, [setInstrumentMoreButtonEl]);

    const handleOpenFinresButton = useCallback(() => {
        if (selectedInstrument) {
            window.open(`https://smart-lab.ru/q/${selectedInstrument.ticker}/f/y/`, '_blank');
        }
        handleInstrumentMenuClose();
    }, [selectedInstrument, handleInstrumentMenuClose]);

    const handleOpenChartButton = useCallback(() => {
        if (selectedInstrument) {
            window.open(`/#/dashboard/instrument/${selectedInstrument.ticker}`, '_blank');
        }
        handleInstrumentMenuClose();
    }, [selectedInstrument, handleInstrumentMenuClose]);

    const showInstrumentMenu = Boolean(instrumentMoreButtonEl);

    return (
        <Paper>
            <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                <InstrumentsAutocomplete onChange={handleTickerChange} showDeactivated={true}/>
            </FormControl>
            <TableContainer sx={{ maxHeight: window.innerHeight - 300 }}>
                <Table size="small" stickyHeader>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={items.length}
                    />
                    <TableBody>
                        {items.map((row) => (
                            <TableRow key={row.name + row.ticker} hover>
                                <TableCell>
                                    {row.model.isActive() ? '' : "(x) "}
                                    <Link underline={'none'} href={`/#/dashboard/instrument/${row.ticker}`}>
                                        #{row.ticker}
                                    </Link>
                                    &nbsp;{row.name}
                                </TableCell>
                                <TableCell>
                                    <Tooltip title={'Меню действий'}>
                                        <IconButton
                                            aria-label="more"
                                            id="instrument-more-button"
                                            aria-controls={showInstrumentMenu ? 'long-menu' : undefined}
                                            aria-expanded={showInstrumentMenu ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={(event) => handleMoreButton(event, row)}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>{prettyPrintPrice(row.lastPrice)}</TableCell>
                                <TableCell>{row.moexLevel || '-'}</TableCell>
                                <TableCell>{row.classCode || '-'}</TableCell>
                                <TableCell>{row.ratio || '-'}</TableCell>
                                <TableCell>{row.shortEnabledFlag ? '+' : '-'}</TableCell>
                                <TableCell>{row.forQualInvestorFlag ? '+' : '-'}</TableCell>
                                <TableCell>{row.apiTradeAvailableFlag ? '+' : '-'}</TableCell>
                                <TableCell>{row.isCandlesInitialised ? '+' : '-'}</TableCell>
                                <TableCell>{`${row.lot}`}</TableCell>
                                <TableCell>{`${row.minPriceIncrement}`}</TableCell>
                                <TableCell>{`${row.riskChangeLowPrice}`}</TableCell>
                                <TableCell>{`${row.riskChangeHighPrice}`}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <InstrumentDialog
                instrument={selectedInstrument}
                showModalDialog={showInstrumentDialog}
                handleCloseModal={handleCloseModal}
                onSubmit={onSubmit}
            />

            <ConfirmationDialog
                dialogTitle={`Удалить инструмент "${selectedInstrument?.ticker} (${selectedInstrument?.name})"?`}
                handleCloseModal={handleCloseModal}
                showModalDialog={showDeleteInstrumentDialog}
                onSubmit={onDeleteInstrumentSubmit}
                submitButtonText="Удалить"
                submitButtonColor={'error'}
            />

            <Menu
                id="instrument-more-menu"
                MenuListProps={{
                    'aria-labelledby': 'instrument-more-button',
                }}
                anchorEl={instrumentMoreButtonEl}
                open={showInstrumentMenu}
                onClose={handleInstrumentMenuClose}
            >
                <MenuItem onClick={handleOpenChartButton}>
                    <SsidChartIcon color={'action'} />
                    <Typography variant="body2" color={'text.secondary'}>
                        &nbsp;Открыть график
                    </Typography>
                </MenuItem>
                <MenuItem onClick={handleOpenFinresButton}>
                    <OpenInNewIcon color={'action'} />
                    <Typography variant="body2" color={'text.secondary'}>
                        &nbsp;Фин. результаты
                    </Typography>
                </MenuItem>
                <MenuItem onClick={handleEditInstrumentBtn}>
                    <EditIcon color={'action'} />
                    <Typography variant="body2" color={'text.secondary'}>
                        &nbsp;Редактировать
                    </Typography>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleDeleteInstrumentBtn}>
                    <DeleteIcon color={'action'} />
                    <Typography variant="body2" color={'text.secondary'}>
                        &nbsp;Удалить
                    </Typography>
                </MenuItem>
            </Menu>
        </Paper>
    );
}
