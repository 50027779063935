import { ShareClassCode } from '@ystb/backend/dist/common';
import { FiboModel } from '@ystb/backend/dist/fibo/fiboModel';
import { IInstrument, InstrumentModel } from '@ystb/backend/dist/instrument/instrumentModel';
import { IInstrumentStats } from '@ystb/backend/dist/instrument/InstrumentStatsModel';
import { rawPriceToNumber } from '@ystb/backend/dist/utils/price';

export class InstrumentModelWithStats {
    name: string;
    figi: string;
    ticker: string;
    isin: string;
    lot: number;
    classCode: ShareClassCode;
    minPriceIncrement: number;
    fibo_one_min: FiboModel;
    fibo_one_hour: FiboModel;
    fibo_one_day: FiboModel;
    fibo_one_month: FiboModel;
    moexLevel: number;
    ratio: number | null;
    lastPrice: number;
    shortEnabledFlag: boolean;
    apiTradeAvailableFlag: boolean;
    isCandlesInitialised: boolean;
    forQualInvestorFlag: boolean;
    riskChangeHighPrice: number;
    riskChangeLowPrice: number;
    model: InstrumentModel;

    constructor(model: IInstrument, stats: IInstrumentStats | undefined) {
        this.name = model.name;
        this.figi = model.figi;
        this.ticker = model.ticker;
        this.isin = model.isin;
        this.lot = model.lot;
        this.classCode = model.class_code;
        this.minPriceIncrement = rawPriceToNumber(model.min_price_increment);
        this.fibo_one_min = model.fibo_one_min;
        this.fibo_one_hour = model.fibo_one_hour;
        this.fibo_one_day = model.fibo_one_day;
        this.fibo_one_month = model.fibo_one_month;
        this.moexLevel = model.moex_level || 0;
        this.ratio = model.ratio;
        let lastPrice = stats?.last_candle.close;
        if (typeof lastPrice === 'string') {
            lastPrice = parseFloat(lastPrice);
        }
        this.lastPrice = lastPrice ? lastPrice : 0;
        this.shortEnabledFlag = model.short_enabled_flag;
        this.apiTradeAvailableFlag = model.api_trade_available_flag;
        this.isCandlesInitialised = model.is_candles_initialised;
        this.forQualInvestorFlag = model.for_qual_investor_flag || false;
        this.riskChangeLowPrice = stats?.risk_change_low_price || 0;
        this.riskChangeHighPrice = stats?.risk_change_high_price || 0;
        this.model = new InstrumentModel(model)
    }
}
