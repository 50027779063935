import * as React from 'react';
import { find } from 'lodash';
import { FormControl, InputLabel, Link, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

// @ts-ignore
import { FiboModel } from '@ystb/backend/dist/fibo/fiboModel';
// @ts-ignore
import { TrendDirection, TimeFrame } from '@ystb/backend/dist/common';
import { prettyPrintPrice } from '@ystb/backend/dist/utils/common';

import AppState from '../AppState';
import { InstrumentsAutocomplete, SelectTickerOption } from '../components/InstrumentsAutocomplete';
import { getTimeframe } from '../utils/common';
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";

interface IFiboModelView extends FiboModel {
    id: string;
    ticker: string;
    name: string;
    updateDateStr: string;
    point1TimeStr: string;
    point2TimeStr: string;
    point1Val: string;
    point2Val: string;
    trendDirection: TrendDirection;
    timeFrame: TimeFrame;
    lastPrice: number;
    moexLevel: number;
}

enum TrendStatus {
    None = '',
    Up = 'uptrend',
    Down = 'downtrend',
}

enum Timeframe {
    None = '',
    FourHour = 'four_hour',
    OneHour = 'one_hour',
    OneDay = 'one_day',
    OneWeek = 'one_week',
    OneMonth = 'one_month',
}

let isActivated = false;

export function FiboTable() {
    const [items, setItems] = useState<IFiboModelView[]>([]);
    const [ticker, setTicker] = React.useState<SelectTickerOption>();
    const [trend, setTrend] = React.useState<TrendStatus>(TrendStatus.None);
    const [timeframe, setTimeframe] = React.useState<Timeframe>(Timeframe.None);
    const [moexLevel, setMoexLevel] = React.useState<string>('');

    useEffect(() => {
        if (isActivated) {
            return;
        }
        isActivated = true;
        setTimeout(() => (isActivated = false), 1000);
        AppState.restClient.fetchFibo().then(async (data) => {
            const models = data
                .filter((item) => {
                    if(!!item.update_date && item.ticker) {
                        const instrument = find(AppState.instruments, ({ ticker }) => ticker === item.ticker);
                        return instrument?.model.isActive();
                    }
                    return false;
                })
                .map((rawModel) => {
                    const model = new FiboModel(rawModel);
                    const ticker = model.ticker;
                    const id = ticker + model.timeFrame;
                    const updateDateStr = AppState.getFormatedDate(model.updateDate.toString());
                    const point1TimeStr = AppState.getFormatedDate(model.point1.time.toString());
                    const point2TimeStr = AppState.getFormatedDate(model.point2.time.toString());
                    const point1Val = model.trendDirection === TrendDirection.Up ? model.point1.low : model.point1.high;
                    const point2Val = model.trendDirection === TrendDirection.Up ? model.point2.high : model.point1.low;
                    const instrument = find(AppState.instruments, ({ ticker }) => ticker === model.ticker);
                    const lastPrice = instrument?.lastPrice || 0;
                    const moexLevel = instrument?.moexLevel || 0;
                    return Object.assign({}, model, {
                        id,
                        ticker,
                        name: instrument?.name || '',
                        updateDateStr,
                        point1TimeStr,
                        point2TimeStr,
                        point1Val: prettyPrintPrice(point1Val),
                        point2Val: prettyPrintPrice(point2Val),
                        trendDirection: (model.trendDirection as string) === 'None' ? '-' : model.trendDirection,
                        lastPrice,
                        moexLevel,
                    });
                });
            setItems(models);
        });
        return;
    }, []);

    const handleTickerChange = (event: React.SyntheticEvent, value: any) => {
        setTicker(value || '');
    };

    const handleTrendChange = (event: SelectChangeEvent) => {
        setTrend(event.target.value as TrendStatus);
    };

    const handleTimeframeChange = (event: SelectChangeEvent) => {
        setTimeframe(event.target.value as Timeframe);
    };

    const handleMoexLevelChange = (event: SelectChangeEvent) => {
        setMoexLevel(event.target.value);
    };

    const columns: GridColDef[] = [
        {
            field: 'ticker',
            headerName: 'Тикер',
            width: 300,
            renderCell: (params) => {
                const row = params.row;
                return (
                    <div>
                        <Link underline={'none'} href={`/#/dashboard/instrument/${row.ticker}`}>
                            #{row.ticker}
                        </Link> {row.name}
                        &nbsp;({getTimeframe(row.timeFrame)})
                    </div>
                );
            },
        },
        {
            field: 'trendDirection',
            headerName: 'Тренд',
            width: 60,
            renderCell: params => {
                const value = params.row.trendDirection;
                if (value === TrendDirection.Up) {
                    return <ArrowUpwardOutlinedIcon color={'success'} fontSize={'small'} />;
                } else if (value === TrendDirection.Down) {
                    return <ArrowDownwardOutlinedIcon color={'error'} fontSize={'small'} />;
                }
                return '';
            }
        },
        {
            field: 'updateDate',
            headerName: 'Дата создания',
            width: 160,
            type: 'dateTime',
        },
        {
            field: 'lastPrice',
            headerName: 'Цена',
            description: 'Цена последней минутной свечи',
            width: 100,
            valueGetter: (lastPrice: number) => {
                if (!lastPrice) {
                    return '-';
                }
                return prettyPrintPrice(lastPrice);
            },
        },
        {
            field: 'point1Val',
            headerName: 'Точка 1',
            type: 'number',
            width: 180,
            valueGetter: (point1Val: number, fibo: IFiboModelView) => {
                if (!fibo) {
                    return '-';
                }
                return `${point1Val} ₽ ${fibo.point1TimeStr}`;
            },
        },
        {
            field: 'point2Val',
            headerName: 'Точка 2',
            type: 'number',
            width: 180,
            valueGetter: (point2Val:  number, fibo: IFiboModelView) => {
                if (!fibo || Number.isNaN(point2Val)) {
                    return '-';
                }
                return `${point2Val} ₽ ${fibo.point2TimeStr}`;
            },
        },
        {
            field: 'fib236',
            headerName: '23.60%',
            width: 100,
            type: 'number',
        },
        {
            field: 'fib382',
            headerName: '38.20%',
            width: 100,
            type: 'number',
        },
        {
            field: 'fib1000',
            headerName: '100.00%',
            width: 100,
            type: 'number',
        },
        {
            field: 'fib1618',
            headerName: '161.80%',
            width: 100,
            type: 'number',
        },
        {
            field: 'fib2118',
            headerName: '211.80%',
            width: 100,
            type: 'number',
        },
        {
            field: 'fib2618',
            headerName: '261.80%',
            width: 100,
            type: 'number',
        },
        {
            field: 'fib2786',
            headerName: '278.60%',
            width: 100,
            type: 'number',
        },
        {
            field: 'fib3618',
            headerName: '361.80%',
            width: 100,
            type: 'number',
        },
        {
            field: 'fib3927',
            headerName: '392.70%',
            width: 100,
            type: 'number',
        },
        {
            field: 'fib4236',
            headerName: '423.60%',
            width: 100,
            type: 'number',
        },
        {
            field: 'fib4618',
            headerName: '461.80%',
            width: 100,
            type: 'number',
        },
        {
            field: 'fib4786',
            headerName: '478.60%',
            width: 100,
            type: 'number',
        },
    ];

    const filterItemsToRender = useCallback(
        (item: IFiboModelView) => {
            let filterByTrend = true;
            let filterTicker = true;
            let filterTimeframe = true;
            let filterMoexLevel = true;

            if (ticker) {
                if (ticker?.ticker) {
                    filterTicker = item.ticker === ticker.ticker;
                } else if (typeof ticker === 'string') {
                    const searchStr = (ticker as string).toLowerCase();
                    filterTicker = item.ticker.toLowerCase().includes(searchStr) || item.name.toLowerCase().includes(searchStr);
                }
            }
            if (trend !== TrendStatus.None) {
                filterByTrend = trend === (item.trendDirection as string);
            }
            if (timeframe !== Timeframe.None) {
                filterTimeframe = timeframe === (item.timeFrame as string);
            }
            if (moexLevel) {
                filterMoexLevel = item.moexLevel.toString() === moexLevel;
            }
            return filterByTrend && filterTicker && filterTimeframe && filterMoexLevel;
        },
        [trend, ticker, timeframe, moexLevel]
    );

    const rows = items.filter(filterItemsToRender);

    return (
        <>
            <FormControl sx={{ m: 1, minWidth: 220 }} size="small">
                <InstrumentsAutocomplete onChange={handleTickerChange} enableInputText={true} clearOnEscape={false} clearOnBlur={false}/>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                <InputLabel id="demo-select-small-label">Тренд</InputLabel>
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={trend}
                    label="Тренд"
                    onChange={handleTrendChange}
                >
                    <MenuItem value={TrendStatus.None}>
                        <em>Все</em>
                    </MenuItem>
                    <MenuItem value={TrendStatus.Up}>Рост</MenuItem>
                    <MenuItem value={TrendStatus.Down}>Падение</MenuItem>
                </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                <InputLabel id="demo-select-small-label">Тайм фрейм</InputLabel>
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={timeframe}
                    label="Тайм фрейм"
                    onChange={handleTimeframeChange}
                >
                    <MenuItem value={Timeframe.None}>
                        <em>Все</em>
                    </MenuItem>
                    <MenuItem value={Timeframe.OneHour}>1 час</MenuItem>
                    <MenuItem value={Timeframe.FourHour}>4 часа</MenuItem>
                    <MenuItem value={Timeframe.OneDay}>1 день</MenuItem>
                    <MenuItem value={Timeframe.OneWeek}>1 неделя</MenuItem>
                    <MenuItem value={Timeframe.OneMonth}>1 месяц</MenuItem>
                </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                <InputLabel id="demo-select-small-label">Эшелон Мосбиржи</InputLabel>
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={moexLevel}
                    label="Эшелон Мосбиржи"
                    onChange={handleMoexLevelChange}
                >
                    <MenuItem value="">
                        <em>Все</em>
                    </MenuItem>
                    <MenuItem value={'1'}>1</MenuItem>
                    <MenuItem value={'2'}>2</MenuItem>
                    <MenuItem value={'3'}>3</MenuItem>
                    <MenuItem value={'0'}>Расписки</MenuItem>
                </Select>
            </FormControl>

            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            updateDate: false,
                            point1Val: false,
                            point2Val: false,
                            fib4786: false,
                            fib4618: false,
                            fib3927: false,
                            fib3118: false,
                            fib2786: false,
                            fib2118: false,
                            fib1786: false,
                        },
                    },
                    pagination: {
                        paginationModel: { page: 0, pageSize: 25 },
                    },
                }}
                pageSizeOptions={[25, 50, 100]}
            />
        </>
    );
}
