import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton/IconButton';
import Button from '@mui/material/Button';

import { AccountType, IUser, User } from '@ystb/backend/dist/user/user';

import AppState from '../AppState';
import { UserDialog } from './components/UserDialog';
import { ConfirmationDialog } from './components/ConfirmationDialog';
import dayjs from 'dayjs';
import { displayDateFormat } from '../constants/common';
import {
    getHumanReadableAccountType,
    getHumanReadableRiskProfile,
    getHumanReadableUserRole,
} from '@ystb/backend/dist/user/user.utils';
import Link from '@mui/material/Link';

let isActivated = false;

interface IUsersStats {
    total: number;
    active: number;
    notRegistered: number;
}

export function UsersTable() {
    const [items, setItems] = useState<User[]>([]);
    const [showUserModalDialog, setShowUserModalDialog] = React.useState(false);
    const [showDeleteUserModalDialog, setShowDeleteUserModalDialog] = React.useState(false);
    const [selectedUser, setSelectedUser] = useState<User | undefined>();
    const [userStats, setUserStats] = useState<IUsersStats>({ total: 0, active: 0, notRegistered: 0 });

    useEffect(() => {
        if (isActivated) {
            return;
        }
        isActivated = true;
        setTimeout(() => (isActivated = false), 1000);
        AppState.restClient.getUsersList().then(async (data) => {
            const users = data.map((model) => new User(model));
            setItems(users);
            let active = 0;
            let notRegistered = 0;
            for (const user of users) {
                if (user.accountType === AccountType.Undefined) {
                    notRegistered++;
                } else if (user.isActive()) {
                    active++;
                }
            }
            setUserStats({
                total: users.length,
                active,
                notRegistered,
            });
        });
    }, []);

    const handleAddUserButton = useCallback(() => setShowUserModalDialog(true), []);
    const handleEditUserButton = useCallback((user: User) => {
        setSelectedUser(user);
        setShowUserModalDialog(true);
    }, []);
    const handleDeleteUserButton = useCallback((user: User) => {
        setSelectedUser(user);
        setShowDeleteUserModalDialog(true);
    }, []);

    const handleCloseModal = useCallback(() => {
        setShowUserModalDialog(false);
        setTimeout(() => setSelectedUser(undefined), 1);
    }, []);

    const handleDeleteUserCloseModal = useCallback(() => {
        setShowDeleteUserModalDialog(false);
    }, []);

    const onSubmit = useCallback(async ({ data, isEditMode }: { data: IUser; isEditMode: boolean }) => {
        try {
            if (isEditMode) {
                await AppState.restClient.updateUser(data);
            } else {
                await AppState.restClient.addUser(data);
            }
            // fixme after adding WS
            window.location.reload();
        } catch {
            //
        }
    }, []);

    const onDeleteUserSubmit = useCallback(async () => {
        try {
            if (selectedUser?.telegramId) {
                await AppState.restClient.deleteUser(selectedUser.telegramId);
                setSelectedUser(undefined);
                setShowDeleteUserModalDialog(false);
                // fixme after adding WS
                window.location.reload();
            }
        } catch {
            //
        }
    }, [selectedUser]);

    return (
        <>
        <Button startIcon={ <PersonAddIcon /> } variant='outlined' onClick={ handleAddUserButton }>
            Добавить
        </Button>
        <span
            style={ { marginLeft: 10 }} > Всего: { userStats.total } | Подписка активна: { userStats.active } | Не подключены: { userStats.notRegistered }</span>
                <br />
                <Table size="small">
                <TableHead>
                <TableRow>
                <TableCell>Пользователь</TableCell>
                <TableCell>Роль</TableCell>
                <TableCell>Риск профиль</TableCell>
                <TableCell>Тариф</TableCell>
                <TableCell>ФИО</TableCell>
                <TableCell>Размер счета</TableCell>
                <TableCell>Шорт</TableCell>
                <TableCell>Квал.</TableCell>
                <TableCell>Польз. соглашение</TableCell>
                <TableCell>Отправлять сообщения</TableCell>
                <TableCell>Дата активация подписки:</TableCell>
                <TableCell>Подписка до:</TableCell>
                <TableCell align="right"></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
            { items.map((row) => (
                <TableRow key={ row.telegram } hover={ true }>
                            <TableCell>
                                <Link href={ `https://t.me/${ row.telegram }` } target='_blank'>
                                    @{ row.telegram }
                                </Link>
                                <br />
                                { row.telegramId }
                                <br />
                                { row.promoCode }
                            </TableCell>
                            <TableCell>{row.isActive() ? '⭐️' : ""}{ getHumanReadableUserRole(row.role) }</TableCell>
                            <TableCell align='center'>{ getHumanReadableRiskProfile(row.riskProfile) }</TableCell>
                            <TableCell align='center'>{ getHumanReadableAccountType(row.accountType) }</TableCell>
                            <TableCell>
                                { row.lastName } { row.firstName } { row.middleName }
                            </TableCell>
                            <TableCell>
                                { Number(row.accountSize).toLocaleString('ru-Ru', { minimumFractionDigits: 0 }) }
                            </TableCell>
                            <TableCell align='center'>{ row.isShortAllowed ? '+' : '-' }</TableCell>
                            <TableCell align='center'>{ row.isQualInvestor ? '+' : '-' }</TableCell>
                            <TableCell align='center'>{ row.eula ? '+' : '-' }</TableCell>
                            <TableCell align='center'>{ row.sendPersonalNotifications ? '+' : '-' }</TableCell>
                            <TableCell align='center'>
                                { row.accountActivatedAt ? dayjs(row.accountActivatedAt).format(displayDateFormat) : '-' }
                            </TableCell>
                            <TableCell align='center'>
                                { row.accountExpiredAt ? dayjs(row.accountExpiredAt).format(displayDateFormat) : '-' }
                            </TableCell>
                            <TableCell align='right'>
                                <IconButton aria-label='edit' size={ 'small' }
                                            onClick={ () => handleEditUserButton(row) }>
                                    <EditIcon />
                                </IconButton>
                                <IconButton
                                    aria-label='delete'
                                    size={ 'small' }
                                    onClick={ () => handleDeleteUserButton(row) }
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
        ))}
        </TableBody>
</Table>

    <UserDialog
        userModel={ selectedUser }
        showModalDialog={ showUserModalDialog }
        handleCloseModal={ handleCloseModal }
        onSubmit={ onSubmit }
    />

    <ConfirmationDialog
        dialogTitle={ `Удалить пользователя "${ selectedUser?.name || selectedUser?.telegram }"?` }
        handleCloseModal={ handleDeleteUserCloseModal }
        showModalDialog={ showDeleteUserModalDialog }
        onSubmit={ onDeleteUserSubmit }
        submitButtonText='Удалить'
        submitButtonColor={ 'error' }
    />
</>
)}
